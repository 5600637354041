import $ from "jquery";
import { logFactory, vendors } from "GlobalSite";

const {
  floatingUi: { computePosition, flip, shift, offset, arrow },
} = vendors;
const compName = "comp_tech_util-tooltipmanager";
const Log = logFactory(compName);
const { log } = Log;

log("--->");

// Main tooltip manager
class TooltipManager {
  constructor({
    tooltipCtaSelector, // Selector for trigger buttons
    tooltipSelector, // Selector for tooltips
    arrowSelector, // Selector for arrows
    $tooltipsContainer, // jQuery container for tooltips
    fnComputePositions, // Optional function for position calculation
  }) {
    this.tooltipCtaSelector = tooltipCtaSelector;
    this.tooltipSelector = tooltipSelector;
    this.arrowSelector = arrowSelector;
    this.$tooltipsContainer = $tooltipsContainer;
    this.fnComputePositions =
      fnComputePositions || this.defaultFnComputePositions;

    this.init();
  }

  // Display a tooltip and update its position
  showTooltip({ button, tooltip }) {
    $(tooltip).css("display", "block").attr("aria-hidden", false);
    this.update({ button, tooltip });
  }

  // Hide a tooltip
  hideTooltip(tooltip) {
    $(tooltip).css("display", "none").attr("aria-hidden", true);
  }

  // Calculate and apply tooltip and arrow positioning
  defaultFnComputePositions({ button, tooltip, $arrowElement }) {
    computePosition(button, tooltip, {
      placement: "bottom",
      middleware: [
        offset(8),
        flip(),
        shift({ padding: 5 }),
        arrow({ element: $arrowElement[0] }),
      ],
    }).then(({ x, y, placement, middlewareData }) => {
      $(tooltip).css({
        left: `${x}px`,
        top: `${y}px`,
      });

      const { x: arrowX, y: arrowY } = middlewareData.arrow;

      const staticSide = {
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right",
      }[placement.split("-")[0]];

      $arrowElement.css({
        left: arrowX != null ? `${arrowX}px` : "",
        top: arrowY != null ? `${arrowY}px` : "",
        right: "",
        bottom: "",
        [staticSide]: "-4px",
      });
    });
  }

  // Update tooltip position
  update({ button, tooltip }) {
    const $arrowElement = $(tooltip).find(this.arrowSelector);
    this.fnComputePositions({ button, tooltip, $arrowElement });
  }

  // Get tooltip element associated with a button
  getTooltipElem({ $button, $container }) {
    const tooltipId = $button.attr("aria-describedby");
    const $tooltip = $container.find(`#${tooltipId}`);
    return $tooltip[0];
  }

  // Initialize observers and event handlers
  init() {
    // Resize observer
    const resizeObserver = new ResizeObserver((entries) => {
      const self = this;
      const $visibleButton = $(
        `${self.tooltipCtaSelector}:not([aria-hidden="true"])`,
      );
      if ($visibleButton.length) {
        $visibleButton.each(function () {
          const $button = $(this);
          const tooltip = self.getTooltipElem({
            $button,
            $container: self.$tooltipsContainer,
          });

          if (tooltip && $(tooltip).css("display") === "block") {
            self.update({ button: this, tooltip });
          }
        });
      }
    });

    // Visibility observer
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const self = this;
        for (const entry of entries) {
          if (entry.intersectionRatio < 1) {
            const $tooltip = $(entry.target);
            const $button = $(`[aria-describedby="${$tooltip.attr("id")}"]`);
            if ($button.length && $tooltip.css("display") === "block") {
              self.update({ button: $button[0], tooltip: $tooltip[0] });
            }
          }
        }
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      },
    );

    resizeObserver.observe(document.body);

    $(this.tooltipSelector).each((_, elem) => {
      intersectionObserver.observe(elem);
    });

    // Event handlers for display/hide
    this.$tooltipsContainer
      .on("click focus", this.tooltipCtaSelector, (event) => {
        const $button = $(event.currentTarget);
        const self = this;
        this.showTooltip({
          button: event.currentTarget,
          tooltip: self.getTooltipElem({
            $button,
            $container: self.$tooltipsContainer,
          }),
        });
      })
      .on("blur", this.tooltipCtaSelector, (event) => {
        const $button = $(event.currentTarget);
        const self = this;
        self.hideTooltip(
          self.getTooltipElem({
            $button,
            $container: self.$tooltipsContainer,
          }),
        );
      })
      .on("keyup", this.tooltipCtaSelector, (event) => {
        if (event.key === "Escape") {
          const $button = $(event.currentTarget);
          $button.trigger("blur");
        }
      });
  }
}

export { TooltipManager };
